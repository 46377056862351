
import { Ref } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import {
  NButton,
  NForm,
  NFormItem,
  NDynamicTags,
  NRow,
  NInput,
  NSpace,
  NPopconfirm,
  NTabs,
  NTag,
  NTabPane,
  NSpin, NText, NAlert, useMessage,
} from 'naive-ui';
import { h } from 'vue';
import TermContent from '@/components/TermContent.vue';
import { Term, TermForm } from '@/models';
import Error404 from '@/components/Error404.vue';
import TermBody from '@/components/TermBody.vue';
import CategorySelect from '@/components/CategorySelect.vue';
import ImageUploader from '@/components/ImageUploader.vue';
import { logToES } from '@/utils';

@Options({
  components: {
    ImageUploader,
    CategorySelect,
    TermBody,
    Error404,
    TermContent,
    NSpin,
    NRow,
    NSpace,
    NDynamicTags,
    NForm,
    NTag,
    NFormItem,
    NInput,
    NButton,
    NText,
    NAlert,
    NTabs,
    NTabPane,
    NPopconfirm,
  },
})
export default class AddTerm extends Vue {
  @Ref('formRef') readonly formRef!: HTMLFormElement;

  alreadyTakenTerm: null | Term = null;

  model: TermForm = {
    name: '',
    category: null,
    image: null,
    aliases: [],
    content: '',
  }

  tags: string[] = []

  mode: 'add' | 'edit' = 'add';

  loading = true;

  editNotFound = false;

  originalName: string | null = null;

  message = useMessage();

  rules = {
    name: [
      {
        required: true,
        trigger: ['input', 'blur'],
        message: 'Provide term',
      },
    ],
    content: [
      {
        required: false,
      },
    ],
  }

  renderTag(tag, index) {
    const exists = this.$store.getters.findTerm(tag.trim());

    return h(
      NTag,
      {
        type: exists ? 'error' : 'default',
        closable: true,
        size: 'large',
        onClose: () => {
          this.model.aliases.splice(index, 1);
        },
      },
      {
        default: () => tag,
      },
    );
  }

  async created() {
    this.mode = this.$route.params.termId ? 'edit' : 'add';

    if (this.mode === 'edit') {
      await this.setupEdit();
    }

    if (this.mode === 'add') {
      await this.setupAdd();
    }

    this.loading = false;
  }

  private async setupEdit() {
    await this.$store.dispatch('getProject', this.$route.params.projectSlug);

    const term = this.$store.state.project.terms[this.$route.params.termId];

    if (!term) {
      this.editNotFound = true;
    } else {
      this.originalName = term.name;
      this.model.name = term.name;
      this.model.content = term.content;
      this.model.category = term.category || null;
      this.model.image = term.image || null;
      this.model.aliases = term.aliases || [];
    }
  }

  private async setupAdd() {
    if (this.$route.query.name) {
      this.model.name = this.$route.query.name as string;
    }
  }

  stopEditing() {
    this.$router.push({
      name: 'term',
      params: {
        termId: this.$route.params.termId,
      },
    });
  }

  hasTakenAliases() {
    return this.model.aliases.find(alias => {
      return this.$store.getters.findTerm(alias);
    });
  }

  async submitForm(e) {
    e.preventDefault();
    this.alreadyTakenTerm = null;

    if (this.hasTakenAliases()) {
      return;
    }

    this.formRef.validate(async (errors) => {
      if (errors) {
        return;
      }

      const term = this.mode === 'add'
        ? await this.$store.dispatch('addTerm', {
          data: this.model,
          projectId: this.$store.state.projectId,
        })
        : await this.$store.dispatch('editTerm', {
          data: this.model,
          projectId: this.$store.state.projectId,
          termId: this.$route.params.termId,
        });

      logToES({
        action: this.mode === 'add' ? 'term-added' : 'term-edited',
        label: `${this.$route.params.projectSlug}-${this.$route.params.termId}`,
        value: {
          user: this.$store.state.user.email,
          content: this.model,
        },
      });

      if (!term.error) {
        this.message.success('Item successfully saved');

        await this.$router.push({
          name: 'term',
          params: {
            termId: term.id,
          },
        });
      }

      if (term.error === 'ALREADY_TAKEN') {
        this.alreadyTakenTerm = term.term;
        console.log('this.alreadyTakenTerm', this.alreadyTakenTerm);
      }
    });
  }

  async deleteItem() {
    await this.$store.dispatch('deleteTerm', {
      termId: this.$route.params.termId,
      projectId: this.$store.state.projectId,
    });

    this.message.success('Item deleted!');

    await this.$router.push({
      name: 'project',
      params: {
        projectId: this.$store.state.projectId,
      },
    });
  }
}
