import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NAutoComplete = _resolveComponent("NAutoComplete")!

  return (_openBlock(), _createBlock(_component_NAutoComplete, {
    "clear-after-select": "",
    "blur-after-select": "",
    onSelect: _ctx.selected,
    size: "large",
    options: _ctx.options,
    value: _ctx.value,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    "get-show": _ctx.getShow,
    placeholder: "Search..."
  }, null, 8, ["onSelect", "options", "value", "get-show"]))
}