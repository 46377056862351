
import { NImage } from 'naive-ui';
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  components: {
    NImage,
  },
})
export default class ImageBox extends Vue {
  @Prop() readonly image!: string

  @Prop({
    default: false,
  }) readonly previewDisabled!: boolean

  get url() {
    return `https://ik.imagekit.io/ubique${this.image}`;
  }
}
