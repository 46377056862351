import 'autolink-js';
import { capitalizeFirstLetter } from '@/utils';

function parseLink(link: string, terms: any) {
  const pluralEnding = link.split(']]')[1];
  const body = link
    .replace('[[', '')
    .split(']]')[0];

  let href = body;
  let anchor = body;

  if (body.includes('|')) {
    href = body.split('|')[0].trim();
    anchor = body.split('|')[1].trim();
  }

  href = capitalizeFirstLetter(href).replaceAll(' ', '_');

  let existingTermId: string | null = null;
  Object.keys(terms).find(termId => {
    if (termId.toLowerCase() === href.toLowerCase()) {
      existingTermId = termId;
      return true;
    }

    if (terms[termId].aliases?.map(alias => alias.toLowerCase()).includes(href.toLowerCase())) {
      existingTermId = termId;
      return true;
    }

    return false;
  });

  if (existingTermId) {
    return `<a href="./${existingTermId}">${anchor}${pluralEnding}</a>`;
  }

  return `<a href="./${href}" class="missing">${anchor}${pluralEnding}</a>`;
}

export function parseWikiText(wikitext: string, terms: any) {
  let output = wikitext;
  const links = output.match(/\[\[(.*?)\]\]e?s?/gmi) || [];

  links.forEach(link => {
    output = output.replace(link, parseLink(link, terms));
  });

  return output;
}
