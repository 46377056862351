import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "space-y-1 mr-8 mt-8",
  "aria-label": "Sidebar",
  style: {"width":"280px"}
}
const _hoisted_2 = { class: "text-2xl mb-4" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_divider = _resolveComponent("n-divider")!
  const _component_NavItem = _resolveComponent("NavItem")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$store.state.project.name), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.name
      }, [
        (item.delimiter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_n_divider)
            ]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_NavItem, {
                onClick: _withModifiers(($event: any) => (_ctx.menuItemClick(item)), ["prevent"]),
                item: item,
                expandable: item.children && item.children.length > 0,
                expanded: _ctx.showCategories
              }, null, 8, ["onClick", "item", "expandable", "expanded"]),
              (_ctx.showCategories && item.children)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (sublink) => {
                      return (_openBlock(), _createBlock(_component_NavItem, {
                        onClick: _withModifiers(($event: any) => (_ctx.menuItemClick(sublink)), ["prevent"]),
                        "is-sublink": true,
                        key: sublink.name,
                        item: sublink
                      }, null, 8, ["onClick", "item"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ], 64))
      ]))
    }), 128))
  ]))
}