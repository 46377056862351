
import { Vue, Options } from 'vue-class-component';
import { NButton, NDivider } from 'naive-ui';
import {
  PlayIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  PencilIcon,
  DocumentDownloadIcon,
  DocumentTextIcon,
  TemplateIcon,
} from '@heroicons/vue/outline';
import NavItem from '@/components/NavItem.vue';

@Options({
  components: {
    NavItem,
    NDivider,
    NButton,
  },
})
export default class SidebarNav extends Vue {
  showCategories = false;

  get navigation() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    return [
      {
        name: 'Home',
        to: {
          name: 'homepage',
          params: {
            projectSlug: this.$route.params.projectSlug,
          },
        },
        icon: HomeIcon,
        current: this.$route.name === 'homepage',
      },
      {
        name: 'Random term',
        onClick() {
          that.$store.dispatch('openRandomTerm');
        },
        icon: PlayIcon,
        current: false,
      },
      {
        name: 'All terms',
        to: {
          name: 'all-terms',
          params: {
            projectSlug: this.$route.params.projectSlug,
          },
        },
        icon: DocumentTextIcon,
        current: this.$route.name === 'all-terms',
        count: this.$store.getters.termsArray.length,
      },
      {
        name: 'Categories',
        onClick() {
          that.showCategories = !that.showCategories;
        },
        icon: TemplateIcon,
        children: this.$store.getters.categories.map((category) => {
          return {
            name: category,
            to: {
              name: 'category',
              params: {
                projectSlug: this.$route.params.projectSlug,
              },
              query: {
                category,
              },
            },
            current: this.$route.name === 'category' && this.$route.query.category === category,
            count: this.$store.getters.getTermsByCategory(category).length,
          };
        }),
      },
      {
        name: 'Draft terms',
        to: {
          name: 'draft-terms',
          params: {
            projectSlug: this.$route.params.projectSlug,
          },
        },
        icon: InboxIcon,
        current: this.$route.name === 'draft-terms',
        count: this.$store.getters.draftTermsArray.length,
      },
      {
        delimiter: true,
      },
      {
        name: 'Writing guide',
        to: {
          name: 'guide',
        },
        icon: PencilIcon,
        current: false,
      },
      {
        name: 'Export',
        onClick() {
          that.$store.dispatch('exportAllTerms');
        },
        icon: DocumentDownloadIcon,
        current: false,
      },
      {
        name: 'All terms content',
        to: {
          name: 'all-terms-content',
        },
        icon: DocumentTextIcon,
        current: this.$route.name === 'all-terms-content',
      },
      {
        name: 'Projects',
        to: {
          name: 'projects',
        },
        icon: FolderIcon,
        current: false,
      },
    ];
  }

  created() {
    this.showCategories = this.$route.name === 'category';
  }

  menuItemClick(item) {
    if (item.to) {
      this.$router.push(item.to);
      return;
    }
    if (item.onClick) {
      item.onClick();
    }
  }
}
