
import { Vue, Options } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { NButton, NSpin } from 'naive-ui';
import TermContent from '@/components/TermContent.vue';
import { logToES, prettifySlug } from '@/utils';
import { Term } from '@/models';

@Options({
  components: {
    TermContent,
    NButton,
    NSpin,
  },
})
export default class TermPage extends Vue {
  term: Term | null = null;

  loading = true;

  get prettifiedTermId() {
    return prettifySlug(this.$route.params.termId as string);
  }

  @Watch('$route.params.termId')
  async onTermIdChange(val) {
    if (val) {
      await this.fetchTerm();
    }
  }

  async created() {
    await this.fetchTerm();

    logToES({
      action: 'term-viewed',
      label: `${this.$route.params.projectSlug}-${this.$route.params.termId}`,
      value: {
        user: this.$store.state.user.email,
      },
    });
  }

  async fetchTerm() {
    this.loading = true;

    await this.$store.dispatch('getProject', this.$route.params.projectSlug);

    const term = this.$store.state.project.terms[this.$route.params.termId];

    if (term) {
      this.term = {
        id: this.$route.params.termId,
        ...term,
      };
    } else {
      this.term = null;
    }
    this.loading = false;
  }
}
