import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import TermPage from '@/views/TermPage.vue';
import ProjectPage from '@/views/ProjectPage.vue';
import AddTerm from '@/views/AddTerm.vue';
import AllTerms from '@/views/AllTerms.vue';
import LoginPage from '@/views/LoginPage.vue';
import SignUpPage from '@/views/SignUpPage.vue';
import { getUser } from '@/auth';
import ProjectsPage from '@/views/ProjectsPage.vue';
import NewProjectPage from '@/views/NewProjectPage.vue';
import AboutPage from '@/views/About.vue';
import Homepage from '@/views/Homepage.vue';
import WritingGuide from '@/views/WritingGuide.vue';
import AllTermsContent from '@/views/AllTermsContent.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: AboutPage,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { requiresGuest: true },
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUpPage,
    meta: { requiresGuest: true },
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectsPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/new-project',
    name: 'new-project',
    component: NewProjectPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/project/:projectSlug/',
    name: 'project',
    redirect: {
      name: 'homepage',
    },
    component: ProjectPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'homepage',
        component: Homepage,
      },
      {
        path: 'guide',
        name: 'guide',
        component: WritingGuide,
      },
      {
        path: 'term/:termId',
        name: 'term',
        component: TermPage,
      },
      {
        path: 'all',
        name: 'all-terms',
        component: AllTerms,
      },
      {
        path: 'all-content',
        name: 'all-terms-content',
        component: AllTermsContent,
      },
      {
        path: 'category',
        name: 'category',
        component: AllTerms,
      },
      {
        path: 'all',
        name: 'draft-terms',
        component: AllTerms,
      },
      {
        path: 'add',
        name: 'add',
        component: AddTerm,
      },
      {
        path: 'edit/:termId',
        name: 'edit',
        component: AddTerm,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log('beforeEach', to);
  const user = await getUser();

  if (to.matched.some(record => record.meta.requiresAuth) && !user) {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    });
    return;
  }

  if (to.matched.some(record => record.meta.requiresGuest) && user) {
    next({
      path: '/projects',
    });
    return;
  }

  next();
});

export default router;
