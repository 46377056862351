

import { NPopconfirm, NButton } from 'naive-ui';
import ImageKit from 'imagekit-javascript';
import { Vue, Options } from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import ImageBox from '@/components/ImageBox.vue';

const imagekit = new ImageKit({
  publicKey: 'public_oXkj6opXVtLx6YEdgljVby/FNyI=',
  urlEndpoint: 'https://ik.imagekit.io/ubique',
  authenticationEndpoint: `${window.location.origin}/api/imagekitAuth`,
});

@Options({
  components: {
    ImageBox,
    NButton,
    NPopconfirm,
  },
})
export default class ImageUploader extends Vue {
  @Prop() readonly modelValue!: string

  @Ref('fileInput') readonly fileInput!: HTMLInputElement;

  remove() {
    this.$emit('update:modelValue', null);
  }

  async upload() {
    if (!this.fileInput.files) {
      return;
    }

    const upload = await imagekit.upload({
      file: this.fileInput.files[0],
      fileName: this.fileInput.files[0].name,
      tags: [this.$store.state.user.email, this.$store.state.project.slug],
    });

    this.$emit('update:modelValue', upload.filePath);
  }
}
