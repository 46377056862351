
import { Vue, Options } from 'vue-class-component';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';
import { MenuIcon, XIcon } from '@heroicons/vue/outline';
import { PlusSmIcon } from '@heroicons/vue/solid';
import { logOut } from '@/auth';
import SearchInput from '@/components/SearchInput.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import LogoSVG from '@/components/LogoSVG.vue';

@Options({
  components: {
    LogoSVG,
    UserAvatar,
    SearchInput,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuIcon,
    PlusSmIcon,
    XIcon,
  },
})
export default class WikiNavbar extends Vue {
  async logOut() {
    console.log('sign out');
    await logOut();

    await this.$router.push('/');
  }
}
