
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  components: {
  },
})
export default class NavItem extends Vue {
  @Prop() readonly item!: any;

  @Prop({ default: false }) readonly isSublink!: boolean;

  @Prop({ default: false }) readonly expandable!: boolean;

  @Prop({ default: false }) readonly expanded!: boolean;
}
