import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "h-8 w-8 rounded-full bg-indigo-600 text-white flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.photo)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _ctx.photo,
        class: "h-8 w-8 rounded-full",
        alt: ""
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.initial), 1))
}