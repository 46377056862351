import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCAiHLZInnrMJsYHaDQdkh4v-_YBqBsbyk',
  authDomain: 'ubique-b0c30.firebaseapp.com',
  projectId: 'ubique-b0c30',
  storageBucket: 'ubique-b0c30.appspot.com',
  messagingSenderId: '475464656352',
  appId: '1:475464656352:web:d2ff3ca8d39e3262f2b785',
};

const app = initializeApp(firebaseConfig);

console.log('initializeApp', app);

const provider = new GoogleAuthProvider();

export const auth = getAuth();

if (process.env.VUE_APP_FIREBASE_AUTH_MOCK) {
  connectAuthEmulator(auth, process.env.VUE_APP_FIREBASE_AUTH_MOCK);
}

export const logOut = () => {
  return signOut(auth);
};

export const loginWithEmailAndPassword = (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const sendEmailVerificationAfterSignup = (user) => {
  return sendEmailVerification(user);
};

export const registerWithEmailAndPassword = (email: string, password: string) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const signInWithGoogleLogin = () => {
  return signInWithPopup(auth, provider);
};

export const getUser = () => {
  return new Promise((resolve) => {
    auth.onAuthStateChanged((user) => {
      resolve(user);
    });
  });
};

(window as any).getUser = getUser;

export const db = getFirestore();
(window as any).db = db;
