
import { ThumbDownIcon, ThumbUpIcon } from '@heroicons/vue/outline';
import { Vue, Options } from 'vue-class-component';
import { logToES } from '@/utils';

@Options({
  components: {
    ThumbDownIcon,
    ThumbUpIcon,
  },
})
export default class PageHelpful extends Vue {
  isHelpful: boolean | null = null;

  vote(isHelpful) {
    this.isHelpful = isHelpful;
    logToES({
      action: 'feedback',
      label: `${this.$route.params.projectSlug}-${this.$route.params.termId}`,
      value: {
        helpful: this.isHelpful ? 1 : 0,
        user: this.$store.state.user.email,
      },
    });
    gtag('event', 'term-feedback', {
      event_category: 'feedback',
      event_label: `${this.$route.params.projectSlug}-${this.$route.params.termId}`,
      value: this.isHelpful ? 1 : 0,
    });
  }
}
