
import sanitizeHtml from 'sanitize-html';
import { marked } from 'marked';
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { parseWikiText } from '@/services/wikitext';

@Options({
  components: {
  },
})
export default class TermContent extends Vue {
  @Prop() readonly content!: string;

  get parsedContent() {
    const markdown = marked.parse(
      sanitizeHtml(this.content),
      {
        gfm: true,
        breaks: true,
      },
    );
    const content = parseWikiText(markdown, this.$store.state.project.terms);

    return content;
  }

  mounted() {
    const anchors = this.$el.getElementsByTagName('a') as Array<HTMLAnchorElement>;

    Array.from(anchors).forEach((anchor) => {
      const url = anchor.getAttribute('href');
      // Skip external links
      if (!url) {
        return;
      }
      if (url.includes('://')) {
        anchor.setAttribute('target', '_blank');
        anchor.classList.add('external');
        return;
      }

      anchor.addEventListener('click', (event) => {
        event.preventDefault();
        this.$router.push(url);
        return false;
      });
    });
  }
}
