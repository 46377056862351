
import {
  NButton,
  NDivider, NForm,
  NFormItem,
  NInput,
} from 'naive-ui';
import { Ref } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import { signInWithGoogleLogin, loginWithEmailAndPassword } from '@/auth';

@Options({
  components: {
    NFormItem,
    NInput,
    NButton,
    NDivider,
    NForm,
  },
})
export default class LoginPage extends Vue {
  @Ref('loginFormRef') readonly loginFormRef!: HTMLFormElement;

  state = 'initial';

  model = {
    email: '',
    password: '',
  }

  loginError: string | null = null;

  rules = {
    email: [
      {
        required: true,
        trigger: ['input', 'blur'],
        message: 'Login is required',
      },
    ],
    password: [
      {
        required: true,
        trigger: ['input', 'blur'],
        message: 'Password is required',
      },
    ],
  };

  async signInWithGoogleLogin() {
    await signInWithGoogleLogin();

    if (this.$route.query.redirect) {
      await this.$router.push(this.$route.query.redirect as string);
      return;
    }

    await this.$router.push('projects');
  }

  login(e) {
    e.preventDefault();
    this.loginFormRef.validate((errors) => {
      if (errors && errors.length !== 0) {
        return;
      }

      this.state = 'loggingWithEmail';

      loginWithEmailAndPassword(this.model.email, this.model.password)
        .then(user => {
          console.log('user', user);
          this.state = 'loggedIn';
          this.$router.push('projects');
        })
        .catch(error => {
          if (error.code === 'auth/invalid-email') {
            this.loginError = 'Invalid email address provided.';
          } else if (error.code === 'auth/user-not-found') {
            this.loginError = 'Incorrect username/email or password.';
          } else if (error.code === 'auth/wrong-password') {
            this.loginError = 'Incorrect username/email or password.';
          } else {
            this.loginError = error.code;
          }
          this.state = 'loggingWithEmailError';
        });
    });
  }
}
