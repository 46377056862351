import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-1 bg-gray-100 inline-flex border border-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NImage = _resolveComponent("NImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NImage, {
      "preview-disabled": _ctx.previewDisabled,
      class: "border border-gray-400",
      width: "200",
      src: _ctx.url
    }, null, 8, ["preview-disabled", "src"])
  ]))
}