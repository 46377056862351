import { toSvg } from 'jdenticon';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(() => resolve(null), ms));
};

export const escapeTermIdDotNotation = (termId: string) => {
  return termId.replaceAll('.', '_');
};

export const prettifySlug = (slug: string) => {
  return capitalizeFirstLetter(slug)
    .replaceAll('_', ' ');
};

export async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export function generateProjectAvatar(seed: string) {
  return toSvg(seed, 48);
}

export function download(filename, textInput) {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8, ${encodeURIComponent(textInput)}`);
  element.setAttribute('download', filename);
  document.body.appendChild(element);
  element.click();
}

(window as any).generateProjectAvatar = generateProjectAvatar;

export function logToES({ action, label, value }) {
  if (window.location.hostname === 'localhost') {
    return;
  }

  const data: any = {
    category: 'ubique',
    action,
    label: label || null,
    value: JSON.stringify(value),
  };

  window.navigator.sendBeacon(
    'https://www.tagvenue.com/logging/tracking/custom_events_v1',
    JSON.stringify(data),
  );
}
