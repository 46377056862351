
import { onAuthStateChanged } from 'firebase/auth';
import { NMessageProvider, NConfigProvider } from 'naive-ui';
import { Options, Vue } from 'vue-class-component';
import SidebarNav from '@/components/SidebarNav.vue';
import SearchInput from '@/components/SearchInput.vue';
import { auth } from '@/auth';
import { logToES } from '@/utils';

@Options({
  components: {
    SearchInput,
    SidebarNav,
    NMessageProvider,
    NConfigProvider,
  },
})
export default class App extends Vue {
  themeOverrides = {
    common: {
      borderRadius: '4px',
      primaryColor: '#3e4e88',
      primaryColorHover: '#28386d',
      textColorHover: '3e4e88',
      primaryColorPressed: '3e4e88',
    },
    Button: {
      colorHoverPrimary: '#28386d',
      colorFocusPrimary: '#28386d',
    },
    Select: {
      peers: {
        InternalSelection: {
          // textColor: '#FF0000',
        },
      },
    },
  };

  created() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.$store.commit('setUser', user);
        logToES({
          action: 'login',
          label: this.$route.params.projectSlug,
          value: {
            user: this.$store.state.user.email,
          },
        });
      } else {
        console.log('User logged out');
      }
    });
  }
}
