
import { Vue, Options } from 'vue-class-component';
import { NButton, NSpin } from 'naive-ui';
import { ChevronRightIcon } from '@heroicons/vue/solid';
import WikiNavbar from '@/components/WikiNavbar.vue';
import { ProjectWithId } from '@/models';

@Options({
  components: {
    WikiNavbar,
    ChevronRightIcon,
    NSpin,
    NButton,
  },
})
export default class ProjectsPage extends Vue {
  projects: ProjectWithId[] = [];

  loading = true;

  async mounted() {
    this.projects = await this.$store.dispatch('getProjects');

    if (this.projects.length === 0) {
      await this.$router.push({ name: 'new-project' });
    }
    this.loading = false;
  }
}
