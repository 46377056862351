import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" }
const _hoisted_2 = {
  key: 0,
  class: "text-center pt-8 text-2xl"
}
const _hoisted_3 = { class: "sm:flex" }
const _hoisted_4 = { class: "p-8 sm:px-16 w-full" }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WikiNavbar = _resolveComponent("WikiNavbar")!
  const _component_SidebarNav = _resolveComponent("SidebarNav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_NMessageProvider = _resolveComponent("NMessageProvider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_WikiNavbar),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.hasAccessToProject === false)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Upss.. Cannot access this project! "))
        : (_ctx.hasAccessToProject === true)
          ? (_openBlock(), _createBlock(_component_NMessageProvider, { key: 1 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_SidebarNav)
                  ]),
                  _createElementVNode("main", _hoisted_4, [
                    _createVNode(_component_router_view, {
                      key: _ctx.$route.fullPath
                    })
                  ])
                ])
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_5))
    ])
  ], 64))
}