
import { Vue, Options } from 'vue-class-component';
import { NButton, NFormItem, NInput } from 'naive-ui';
import { ProjectWithId } from '@/models';
import WikiNavbar from '@/components/WikiNavbar.vue';

@Options({
  components: {
    WikiNavbar,
    NFormItem,
    NInput,
    NButton,
  },
})
export default class NewProjectPage extends Vue {
  projects: ProjectWithId[] = [];

  model = {
    name: '',
  }

  async addProject() {
    if (!this.model.name) {
      return;
    }

    const slug = await this.$store.dispatch('addProject', this.model.name);

    await this.$router.push({
      name: 'project',
      params: {
        projectSlug: slug,
      },
    });
  }
}
