<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="max-w-7xl mx-auto py-24 px-4 bg-white sm:px-6 lg:px-8">
    <h2 class="text-3xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
      Pricing plans for teams of all sizes
    </h2>
    <p class="mt-6 max-w-2xl text-xl text-gray-500">
      Choose an affordable plan that's packed with the best features for engaging your audience, creating customer loyalty, and driving sales.
    </p>

    <!-- Tiers -->
    <div class="mt-24 sm:flex">
      <div v-for="tier in pricing.tiers"
           :key="tier.title"
           class="my-8 sm:my-0 sm:w-1/3 mx-2 justify-between relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
      >
        <div class="flex-1">
          <h3 class="text-xl font-semibold text-gray-900">{{ tier.title }}</h3>
          <p v-if="tier.mostPopular" class="absolute top-0 py-1.5 px-4 bg-indigo-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">Most popular</p>
          <p v-if="tier.price" class="mt-4 flex items-baseline text-gray-900">
            <span class="text-5xl font-extrabold tracking-tight">${{ tier.price }}</span>
            <span class="ml-1 text-xl font-semibold">{{ tier.frequency }}</span>
          </p>
          <p class="mt-6 text-gray-500">{{ tier.description }}</p>

          <!-- Feature list -->
          <ul role="list" class="mt-6 space-y-6">
            <li v-for="feature in tier.features" :key="feature" class="flex">
              <CheckIcon class="flex-shrink-0 w-6 h-6 text-indigo-500" aria-hidden="true" />
              <span class="ml-3 text-gray-500">{{ feature }}</span>
            </li>
          </ul>
        </div>

        <router-link :to="{name: 'login'}"
           :class="[tier.mostPopular ? 'bg-indigo-500 text-white hover:bg-indigo-600' : 'bg-indigo-50 text-indigo-700 hover:bg-indigo-100', 'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium']">
          {{ tier.cta }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/outline';

const pricing = {
  tiers: [
    {
      title: 'Free',
      price: 0,
      frequency: '/month',
      description: 'The essentials to provide your best work for clients.',
      features: [
        'Single project',
        'Up to 100 terms',
      ],
      cta: 'Get started',
      mostPopular: false,
    },
    {
      title: 'Startup',
      price: 50,
      frequency: '/month',
      description: 'A plan that scales with your rapidly growing business.',
      features: [
        'Unlimited projects',
        'Unlimited terms',
        'Export option',
      ],
      cta: 'Start free trial',
      mostPopular: true,
    },
    {
      title: 'Self hosted',
      description: 'Run on your own infrastructure',
      features: [
        'Unlimited projects',
        'Unlimited terms',
        'Export option',
      ],
      cta: 'Contact us',
      mostPopular: false,
    },
  ],
};

export default {
  components: {
    CheckIcon,
  },
  setup() {
    return {
      pricing,
    };
  },
};
</script>
