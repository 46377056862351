
import { flatten } from 'lodash';
import Fuse from 'fuse.js';
import { Vue, Options } from 'vue-class-component';
import { NAutoComplete } from 'naive-ui';
import { Watch } from 'vue-property-decorator';
import { Term } from '@/models';

@Options({
  components: {
    NAutoComplete,
  },
})
export default class SearchInput extends Vue {
  value = '';

  options: any[] = [];

  @Watch('$route')
  async onRouteChange() {
    this.value = '';
    this.$store.commit('setSearch', '');
  }

  getShow() {
    return true;
  }

  get fuseTerms() {
    const terms = this.$store.getters.termsArray.map(term => {
      return {
        name: term.name,
        id: term.id,
        category: term.category,
        aliases: (term.aliases || []).join(', '),
      };
    });

    return new Fuse(terms, {
      includeScore: true,
      threshold: 0.4,
      keys: ['name', 'aliases'],
    });
  }

  @Watch('value')
  async updateOptions() {
    if (!this.value) {
      this.options = [];
      return;
    }

    const terms = this.fuseTerms
      .search(this.value)
      .map(e => e.item) as unknown as Term[];

    const options = flatten(terms.map(term => {
      const items = [
        {
          label: term.name + (term.aliases ? ` (${term.aliases})` : ''),
          category: term.category,
          value: term.id,
        },
      ];

      return items;
    }));

    const categories: string[] = [];

    options.forEach(option => {
      if (option.category && !categories.includes(option.category)) {
        categories.push(option.category);
      }
    });

    const categoryGroups = categories.map((category => {
      return {
        type: 'group',
        label: category,
        key: `category-${category}`,
        children: options.filter(option => option.category === category),
      };
    }));

    const orphans = options.filter(option => !option.category);

    this.options = [
      ...categoryGroups,
      ...orphans,
    ];

    this.$store.commit('setSearch', this.value);
  }

  async selected(termId: string) {
    const term = termId.split('-ALIAS-')[0];
    await this.$router.push({
      name: 'term',
      params: {
        termId: term,
      },
    });
  }
}
