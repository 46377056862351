
import { Vue, Options } from 'vue-class-component';
import { sortBy } from 'lodash';
import { NButton, NSpin } from 'naive-ui';
import TermContent from '@/components/TermContent.vue';
import ImageBox from '@/components/ImageBox.vue';
import TermBody from '@/components/TermBody.vue';

@Options({
  components: {
    TermBody,
    ImageBox,
    TermContent,
    NSpin,
    NButton,
  },
})
export default class AllTermsContent extends Vue {
  loading = true;

  get terms() {
    return sortBy(this.$store.getters.termsArray, 'name');
  }

  async created() {
    await this.$store.dispatch('getProject', this.$route.params.projectSlug);
    this.loading = false;
  }
}
