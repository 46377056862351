
import { NTag, NButton } from 'naive-ui';
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Term } from '@/models';
import TermBody from '@/components/TermBody.vue';
import ImageBox from '@/components/ImageBox.vue';
import PageHelpful from '@/components/PageHelpful.vue';

@Options({
  components: {
    PageHelpful,
    ImageBox,
    TermBody,
    NTag,
    NButton,
  },
})
export default class TermContent extends Vue {
  @Prop() readonly term!: Term;

  @Prop({default: true}) readonly showHeadline!: boolean

  get lastChanged() {
    if (!this.term.updatedAt) {
      return null;
    }

    return (new Date(this.term.updatedAt.seconds * 1000)).toLocaleDateString();
  }

  mounted() {
    gtag('event', 'view_item', {
      event_category: 'engagement',
      event_label: `${this.$route.params.projectSlug}-${this.term.id}`,
    });
  }
}
