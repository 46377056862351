
import { Vue, Options } from 'vue-class-component';
import { NButton, NSpin } from 'naive-ui';
import TermContent from '@/components/TermContent.vue';
import { Term } from '@/models';

@Options({
  components: {
    TermContent,
    NButton,
    NSpin,
  },
})
export default class Homepage extends Vue {
  term: Term | null = null;

  loading = true;

  async created() {
    await this.fetchTerm();
  }

  async fetchTerm() {
    this.loading = true;

    await this.$store.dispatch('getProject', this.$route.params.projectSlug);

    const term = this.$store.state.project.terms['Wiki:Homepage'];

    if (term) {
      this.term = {
        id: 'Wiki:Homepage',
        ...term,
      };
    } else {
      this.term = null;
    }
    this.loading = false;
  }
}
