
import { Vue, Options } from 'vue-class-component';
import { NMessageProvider } from 'naive-ui';
import SearchInput from '@/components/SearchInput.vue';
import SidebarNav from '@/components/SidebarNav.vue';
import WikiNavbar from '@/components/WikiNavbar.vue';

@Options({
  components: {
    WikiNavbar,
    SearchInput,
    SidebarNav,
    NMessageProvider,
  },
})
export default class ProjectPage extends Vue {
  hasAccessToProject: null | boolean = null;

  async created() {
    await this.$store.dispatch('getProject', this.$route.params.projectSlug);

    this.hasAccessToProject = this.$store.state.project !== null;
  }
}
