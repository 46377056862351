
import { sortBy, uniq } from 'lodash';
import { Vue, Options } from 'vue-class-component';
import { NButton, NSpin } from 'naive-ui';
import { Term } from '@/models';

@Options({
  components: {
    NSpin,
    NButton,
  },
})
export default class AllTerms extends Vue {
  loading = true;

  get title() {
    if (this.$route.name === 'category') {
      return this.$route.query.category;
    }

    if (this.$route.name === 'draft-terms') {
      return 'Draft terms';
    }

    return 'All terms';
  }

  get categories() {
    const existingCategories = this.$route.name === 'draft-terms'
      ? this.$store.getters.draftCategories
      : this.$store.getters.categories;

    const hasOther = !!this.terms.find(term => !term.category);

    return [...existingCategories, ...(hasOther ? ['Other'] : [])];
  }

  get terms(): Term[] {
    let terms = this.$route.name === 'draft-terms'
      ? this.$store.getters.draftTermsArray
      : this.$store.getters.termsArray;

    if (this.$route.query.category) {
      terms = terms.filter(term => term.category === this.$route.query.category);
    }

    return terms;
  }

  get groupedTerms() {
    const terms = this.terms;

    let letters = terms.map(term => term.name[0].toLowerCase());

    letters = sortBy(uniq(letters));

    return letters.map(letter => {
      const items = sortBy(
        terms.filter(term => term.name.toLowerCase().startsWith(letter)),
        'name',
      );
      return {
        letter,
        items,
      };
    });
  }

  async created() {
    await this.$store.dispatch('getProject', this.$route.params.projectSlug);
    this.loading = false;
  }
}
