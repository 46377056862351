import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeroSection = _resolveComponent("HeroSection")!
  const _component_PricingSection = _resolveComponent("PricingSection")!
  const _component_FooterSection = _resolveComponent("FooterSection")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeroSection),
    _createVNode(_component_PricingSection),
    _createVNode(_component_FooterSection)
  ], 64))
}