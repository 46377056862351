
import { Vue, Options } from 'vue-class-component';

@Options({
  components: {
  },
})
export default class TermContent extends Vue {
  get photo() {
    return this.$store.state.user?.photoURL;
  }

  get initial() {
    return this.$store.state.user.email[0].toUpperCase();
  }
}
