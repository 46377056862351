
import { Options, Vue } from 'vue-class-component';
import PricingSection from '@/components/PricingSection.vue';
import HeroSection from '@/components/HeroSection.vue';
import FAQSection from '@/components/FAQSection.vue';
import FooterSection from '@/components/FooterSection.vue';

@Options({
  components: {
    FooterSection,
    FAQSection,
    HeroSection,
    PricingSection,
  },
})
export default class AboutPage extends Vue {
}
