
import {
  NButton,
  NDivider, NForm,
  NFormItem,
  NInput,
} from 'naive-ui';
import { Ref } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import {
  signInWithGoogleLogin,
  registerWithEmailAndPassword,
  sendEmailVerificationAfterSignup,
} from '@/auth';

@Options({
  components: {
    NFormItem,
    NInput,
    NButton,
    NDivider,
    NForm,
  },
})
export default class SignUpPage extends Vue {
  @Ref('loginFormRef') readonly loginFormRef!: HTMLFormElement;

  state = 'initial';

  model = {
    email: '',
    password: '',
  }

  loginError: string | null = null;

  rules = {
    email: [
      {
        required: true,
        trigger: ['input', 'blur'],
        message: 'Login is required',
      },
    ],
    password: [
      {
        required: true,
        trigger: ['input', 'blur'],
        message: 'Password is required',
      },
    ],
  };

  async signInWithGoogleLogin() {
    await signInWithGoogleLogin();
    await this.$router.push('projects');
  }

  async signup(e) {
    e.preventDefault();
    this.loginFormRef.validate((errors) => {
      if (errors && errors.length !== 0) {
        return;
      }

      this.state = 'loggingWithEmail';

      registerWithEmailAndPassword(this.model.email, this.model.password)
        .then(user => {
          console.log('user', user);
          sendEmailVerificationAfterSignup(user.user);
          // eslint-disable-next-line no-alert
          alert('Please verify your email!');
        })
        .catch(error => {
          if (error.code === 'auth/invalid-email') {
            this.loginError = 'Invalid email address provided.';
          } else if (error.code === 'auth/weak-password') {
            console.log('error', error);
            this.loginError = 'Password must contain at least 6 characters';
          } else if (error.code === 'auth/email-already-in-use') {
            console.log('error', error);
            this.loginError = 'This email is already taken';
          } else {
            console.log('error', error);
            this.loginError = error.code;
          }
          this.state = 'loggingWithEmailError';
        });
    });
  }
}
