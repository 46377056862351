
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { MenuIcon, XIcon } from '@heroicons/vue/outline';
import { Options, Vue } from 'vue-class-component';
import PricingSection from '@/components/PricingSection.vue';
import LogoSVG from '@/components/LogoSVG.vue';

@Options({
  components: {
    LogoSVG,
    PricingSection,
    Popover,
    PopoverButton,
    PopoverPanel,
    MenuIcon,
    XIcon,
  },
})
export default class HeroSection extends Vue {
  navigation = [
    // { name: 'Product', href: '#' },
    // { name: 'Features', href: '#' },
    // { name: 'Marketplace', href: '#' },
    // { name: 'Company', href: '#' },
  ];
}
