
import { NSelect, NInput } from 'naive-ui';
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { uniqBy } from 'lodash';

@Options({
  components: {
    NSelect,
    NInput,
  },
})
export default class CategorySelect extends Vue {
  @Prop() readonly modelValue!: string;

  value = '';

  newCategoryEditing = '';

  newCategory = '';

  get options() {
    const existingCategories = this.$store.getters.categories
      .map((option) => {
        return {
          label: option,
          value: option,
        };
      });

    const newCategories: any[] = [];

    if (this.newCategory) {
      newCategories.push({
        label: this.newCategory,
        value: this.newCategory,
      });
    }

    return uniqBy([
      ...existingCategories,
      ...newCategories,
    ], 'value');
  }

  addNewCategory(event) {
    const value = event.target.value;
    console.log('addNewCategory', value);
    this.newCategory = value;
    this.newCategoryEditing = '';
    this.value = value;
    this.$emit('update:modelValue', value);
  }

  getShow() {
    return true;
  }

  mounted() {
    this.value = this.modelValue;
  }
}
