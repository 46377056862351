import { sample, sortBy, uniq } from 'lodash';
import { createStore } from 'vuex';
import slugify from 'slugify';
import {
  collection, query, where, getDocs,
  addDoc, doc, updateDoc,
  deleteField, serverTimestamp,
} from 'firebase/firestore';
import { User } from 'firebase/auth';
import { db } from '@/auth';
import { Project } from '@/models';
import { toTermSlug } from '@/repository/terms';
import { download, escapeTermIdDotNotation, generateProjectAvatar } from '@/utils';
import router from '@/router';

const projectsRef = collection(db, 'projects');

interface State {
  user: null | User,
  project: null | Project,
  projectId: null | string,
  search: string,
}
const storeState: State = {
  user: null,
  project: null,
  projectId: null,
  search: '',
};

export default createStore({
  state: storeState,
  getters: {
    categories(state, getters) {
      return uniq(sortBy(getters.termsArray.map(term => term.category).filter(category => category)));
    },
    getTermsByCategory(state, getters) {
      return (category) => {
        return getters.termsArray.filter(term => term.category === category);
      };
    },
    draftCategories(state, getters) {
      return uniq(sortBy(getters.draftTermsArray.map(term => term.category).filter(category => category)));
    },
    categoryIds(state, getters) {
      return getters.categories.map(category => category.toLowerCase());
    },
    findTerm(state, getters) {
      return (term) => {
        return getters.termsArray.find(_term => _term.name.toLowerCase() === term.toLowerCase());
      };
    },
    termsArray(state) {
      if (state.project === null) {
        return [];
      }
      return Object.entries(state.project.terms).map(([termId, termObj]) => {
        return {
          ...termObj,
          id: termId,
        };
      }).filter((term) => {
        return !term.name.startsWith('Wiki:');
      });
    },
    draftTermsArray(state, getters) {
      return getters.termsArray.filter((term) => {
        return term.content.trim() === '';
      });
    },
    allNonSpecialTermIds(state) {
      if (state.project === null) {
        return [];
      }

      return Object.keys(state.project.terms).filter((term: string) => {
        return !term.startsWith('Wiki:');
      });
    },
    userDomain(state) {
      if (!state?.user?.email) {
        return null;
      }

      return state.user.email.split('@')[1];
    },
    termSavingMeta(state) {
      if (!state.user) {
        throw new Error('Tried getting saving meta without user');
      }

      return {
        updatedAt: serverTimestamp(),
        updatedByUid: state.user.uid,
        updatedByName: state.user.displayName,
      };
    },
  },
  mutations: {
    setSearch(state: State, value) {
      state.search = value;
    },
    setUser(state: State, user) {
      state.user = user;
    },
    setProject(state: State, { data, projectId}) {
      state.project = data;
      state.projectId = projectId;
    },
  },
  actions: {
    exportAllTerms({ state }) {
      const terms = JSON.stringify(state.project?.terms, null, 2);
      download('ubique-export.json', terms);
    },
    openRandomTerm({ state, getters }) {
      if (!state.project || getters.allNonSpecialTermIds.length === 0) {
        return;
      }

      router.push({
        name: 'term',
        params: {
          termId: sample(getters.allNonSpecialTermIds),
        },
      });
    },
    async getProjects({ state, getters }) {
      if (!state.user) {
        throw new Error('Tried reading projects without logging in');
      }

      const q = query(
        projectsRef,
        where('members', 'array-contains-any', [state.user.uid, getters.userDomain]),
      );

      const querySnapshot = await getDocs(q);
      const projects: any = [];
      querySnapshot.forEach((_doc) => {
        projects.push({
          id: _doc.id,
          aa: 2,
          avatar: generateProjectAvatar(_doc.id),
          data: _doc.data(),
        });
      });

      return projects;
    },
    async addProject({ state }, name: string) {
      if (!state.user) {
        throw new Error('Tried adding project without user logged in');
      }

      const slug = slugify(name);

      const newProject: Project = {
        slug,
        name,
        members: [state.user.uid],
        membersRole: {
          [state.user.uid]: 'owner',
        },
        terms: {},
      };

      await addDoc(projectsRef, newProject);

      return slug;
    },
    async addTerm({ state, getters }, { data, projectId }) {
      if (!state.user) {
        throw new Error('Tried adding term without user logged in');
      }

      if (getters.findTerm(data.name)) {
        return {
          error: 'ALREADY_TAKEN',
          term: getters.findTerm(data.name),
        };
      }

      const id = escapeTermIdDotNotation(toTermSlug(data.name));

      const projectRef = doc(db, 'projects', projectId);

      await updateDoc(projectRef, {
        [`terms.${id}`]: {
          ...data,
          ...getters.termSavingMeta,
        },
      });

      return {
        error: null,
        id,
      };
    },
    async deleteTerm({ state }, { termId, projectId }) {
      console.log('delete term dispatch');
      if (!state.user) {
        throw new Error('Tried adding term without user logged in');
      }

      const projectRef = doc(db, 'projects', projectId);

      await updateDoc(projectRef, {
        [`terms.${escapeTermIdDotNotation(termId)}`]: deleteField(),
      });

      return {
        error: null,
      };
    },
    async editTerm({ state, getters }, { data, projectId, termId }) {
      if (!state.user) {
        throw new Error('Tried adding term without user logged in');
      }
      const projectRef = doc(db, 'projects', projectId);

      await updateDoc(projectRef, {
        [`terms.${termId}`]: {
          ...data,
          ...getters.termSavingMeta,
        },
      });

      return {
        error: null,
        id: termId,
      };
    },
    async getProject({ commit, state, getters }, projectSlug) {
      if (!state.user) {
        throw new Error('Tried getting project without user logged in');
      }

      console.log('projectSlug', projectSlug);

      const q = query(
        collection(db, 'projects'),
        where('slug', '==', projectSlug),
        where('members', 'array-contains-any', [state.user.uid, getters.userDomain]),
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length === 0) {
        commit('setProject', {
          data: null,
          id: null,
        });
        return;
      }

      commit('setProject', {
        data: querySnapshot.docs[0].data(),
        projectId: querySnapshot.docs[0].id,
      });
    },
  },
  modules: {
  },
});
